<template>
  <section>
    <Dialog :closable="false" v-model:visible="displayModal" :style="{width: '40vw'}" :modal="true">
        <template #header>
          <div class="md:flex items-center w-full">
            <div class="flex gap-2 items-center w-full md:w-6/12">
              <i class="pi pi-info-circle"></i>
              <p class="font-medium text-xs">Actualizar paciente {{ pacienteSeleccionado.U_ACS_NmbPct }}</p>
            </div>
            <div class="flex gap-2 items-center justify-end w-full md:w-6/12" v-if="pacienteSeleccionado.updatedBy">
              <i class="pi pi-reload text-gray-600 text-xs"></i>
              <p class="text-xs text-gray-600 italic">Úlmita actualización: {{ pacienteSeleccionado.updatedBy}} a las {{ dayjs(pacienteSeleccionado.updatedAt).format('YYYY-MM-DD') }}</p>
            </div>
          </div>
        </template>
        <div class="w-full">
          <div class="w-full flex flex-wrap text-xs items-center">
            <div class="w-full md:w-3/12">
              <p class="font-medium">No. Identificación:</p>
            </div>
            <div class="w-9/12">
              <input type="text" disabled="true" v-model="pacienteSeleccionado.U_ACS_NmrIdentf" class="border rounded-md w-full p-2 mb-1">
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Primer Apellido:</p>
            </div>
            <div class="w-9/12">
              <input v-model="pacienteSeleccionado.U_ACS_PrimerAplld" type="text" :class="actualizandoPaciente && !pacienteSeleccionado.U_ACS_PrimerAplld ? 'border border-red-400' : 'border mb-1'" class="rounded-md w-full p-2">
              <span v-if="actualizandoPaciente && !pacienteSeleccionado.U_ACS_PrimerAplld" class="text-red-400 italic">Este campo es obligatorio</span>
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Segundo Apellido:</p>
            </div>
            <div class="w-9/12">
              <input v-model="pacienteSeleccionado.U_ACS_SegundoAplld" type="text" class="border rounded-md w-full p-2 mb-1">
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Primer Nombre:</p>
            </div>
            <div class="w-9/12">
              <input v-model="pacienteSeleccionado.U_ACS_PrimerNmb" type="text" :class="actualizandoPaciente && !pacienteSeleccionado.U_ACS_PrimerNmb ? 'border border-red-400' : 'border mb-1'" class="rounded-md w-full p-2">
              <span v-if="actualizandoPaciente && !pacienteSeleccionado.U_ACS_PrimerNmb" class="text-red-400 italic mb-1">Este campo es obligatorio</span>
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Segundo Nombre:</p>
            </div>
            <div class="w-9/12">
              <input v-model="pacienteSeleccionado.U_ACS_SegundoNmb" type="text" class="border rounded-md w-full p-2 mb-1">
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Género:</p>
            </div>
            <div class="w-9/12">
              <Dropdown class="border border-gray-300 rounded-md w-full mb-1 text-xs" inputClass="text-xs" v-model="pacienteSeleccionado.U_ACS_Sexo" :options="generos" />
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Fecha Nacimiento:</p>
            </div>
            <div class="w-9/12">
              <input v-model="pacienteSeleccionado.U_ACS_FchNacim" type="date" :class="actualizandoPaciente && !pacienteSeleccionado.U_ACS_FchNacim ? 'border border-red-400' : 'border mb-1'" class="rounded-md w-full p-2">
              <span v-if="actualizandoPaciente && !pacienteSeleccionado.U_ACS_FchNacim" class="text-red-400 italic mb-1">Este campo es obligatorio</span>
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Regimen:</p>
            </div>
            <div class="w-9/12 text-xs">
              <Dropdown v-model="pacienteSeleccionado.U_ACS_Regimen" :options="regimenes" panelClass="text-xs capitalize text-xs" inputClass="capitalize text-xs" class="border border-gray-300 rounded-md w-full mb-1 capitalize text-xs" />
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium">Rango / Nivel:</p>
            </div>
            <div class="w-9/12">
              <Dropdown v-model="pacienteSeleccionado.U_ACS_Nivel" inputClass="text-xs" panelClass="text-xs text-xs" :options="rangosNiveles.filter(a => a.Nombre.includes(pacienteSeleccionado.U_ACS_Regimen))" optionLabel="Nombre" optionValue="Code" class="border border-gray-300 rounded-md w-full mb-1 capitalize text-xs"/>
              <span v-if="actualizandoPaciente && !pacienteSeleccionado.U_ACS_Nivel" class="text-red-400 italic mb-1">Este campo es obligatorio</span>
            </div>
          </div>
          <hr class="my-6"/>
          <div class="w-full text-center mb-2">
            <p class="text-sm">Detalle de Zonificación</p>
          </div>
          <div class="w-full flex flex-wrap text-xs items-center">
            <div class="w-full md:w-3/12">
              <p class="font-medium mb-1">Zonificación:</p>
            </div>
            <div class="w-9/12">
              <AutoComplete
                class="text-xs w-full"
                :class="actualizandoPaciente && !pacientesStore._zonificacion_seleccionada ? 'border border-red-400 rounded-md' : ''"
                panelClass="text-xs"
                inputClass="text-xs w-full"
                v-model="pacientesStore.zonificacion_seleccionada"
                :suggestions="listadoLocalizaciones"
                @complete="obtenerLocalizaciones(pacientesStore._zonificacion_seleccionada)"
                field="Zonificacion"
              />
              <span v-if="actualizandoPaciente && !pacientesStore._zonificacion_seleccionada" class="text-red-400 italic mb-1">Este campo es obligatorio</span>
            </div>
          </div>
          <hr class="my-6"/>
          <div class="w-full text-center mb-2">
            <p class="text-sm">Detalle de Contacto</p>
          </div>
          <div class="w-full flex flex-wrap text-xs items-center">
            <div class="w-full md:w-3/12">
              <p class="font-medium mb-1"># Teléfono/Celular:</p>
            </div>
            <div class="w-9/12">
              <input type="text" v-model="pacienteSeleccionado.U_ACS_Movil" class="border rounded-md w-full p-1 mb-1">
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium mb-1"># Teléfono Fijo:</p>
            </div>
            <div class="w-9/12">
              <input type="text" v-model="pacienteSeleccionado.U_ACS_Telef" class="border rounded-md w-full p-1 mb-1">
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium mb-1"># Email:</p>
            </div>
            <div class="w-9/12">
              <input type="text" v-model="pacienteSeleccionado.U_ACS_EMail" class="border rounded-md w-full p-1 mb-1">
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium mb-1">Localización:</p>
            </div>
            <div class="w-9/12">
              <AutoComplete
                class="text-xs w-full mb-1"
                :class="actualizandoPaciente && !pacientesStore._localizacion_seleccionada ? 'border border-red-400 rounded-md' : 'border-gray-300'"
                panelClass="text-xs"
                inputClass="text-xs w-full p-1 border-gray-300"
                v-model="pacientesStore.localizacion_seleccionada"
                :suggestions="listadoLocalizaciones"
                @complete="obtenerLocalizaciones(pacientesStore._localizacion_seleccionada)"
                field="Zonificacion"
                :forceSelection="true"
              />
              <span v-if="actualizandoPaciente && !pacientesStore._localizacion_seleccionada" class="text-red-400 italic mb-1">Este campo es obligatorio</span>
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium mb-1">Barrio:</p>
            </div>
            <div class="w-9/12">
              <input type="text" v-model="pacienteSeleccionado.U_PHR_barrio_domicilio" class="border rounded-md w-full p-1 mb-1">
            </div>
            <div class="w-full md:w-3/12">
              <p class="font-medium mb-1">Dirección Domicilio:</p>
            </div>
            <div class="w-9/12">
              <input type="text" v-model="pacienteSeleccionado.U_ACS_Domicilio" class="border rounded-md w-full p-1 mb-1">
            </div>
          </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" @click="cerrarModal" class="p-button-text"/>
            <Button label="Actualizar" icon="pi pi-check" @click="actualizarPaciente" autofocus />
        </template>
    </Dialog>
  </section>
</template>
<script>
  import { onMounted, computed, ref } from 'vue'
  import { usePacientesStore } from '../stores/pacientes.store'
  import { useCalculoCuotaStore } from '../stores/calculo_cuota.store'
  import { useLocalizacionesStore } from '../stores/localizaciones.store'
  import { useOrdenesStore } from '../stores/ordenes.store'
  import { useToast } from 'primevue/usetoast'
  import Swal from 'sweetalert2'
  export default {
    name: 'modalEditarPaciente',
    emits: ['cambioData'],
    setup (props, { emit }) {
      // Servicios
      const toast = useToast()
      // Stores
      const pacientesStore = usePacientesStore()
      const calculoCuotaStore = useCalculoCuotaStore()
      const localizacionesStore = useLocalizacionesStore()
      const localizacionSeleccionada = ref('')
      const ordenesStore = useOrdenesStore()
      // Referencias
      const actualizandoPaciente = ref(false)
      const generos = ref(['Femenino', 'Masculino'])
      // Computadas
      const pacienteSeleccionado = computed(() => pacientesStore._paciente_seleccionado)
      const displayModal = computed(() => pacientesStore._display_editar_paciente)
      const rangosNiveles = computed(() => calculoCuotaStore._rangos_niveles)
      const regimenes = computed(() => calculoCuotaStore._regimen_tipos)
      const listadoLocalizaciones = computed(() => localizacionesStore._localizaciones)
      // Métodos
      const cerrarModal = () => {
        pacientesStore.$patch((state) => {
          state.display_editar_paciente = false
        })
        actualizandoPaciente.value = false
      }
      const actualizarPaciente = () => {
        actualizandoPaciente.value = true
        if (
          !pacienteSeleccionado.value.U_ACS_PrimerAplld ||
          !pacienteSeleccionado.value.U_ACS_PrimerNmb ||
          !pacienteSeleccionado.value.U_ACS_Sexo ||
          !pacienteSeleccionado.value.U_ACS_FchNacim ||
          !pacienteSeleccionado.value.U_ACS_Nivel ||
          !pacientesStore._zonificacion_seleccionada ||
          !pacientesStore._localizacion_seleccionada
        ) {
          actualizandoPaciente.value = true
          return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes completar los campos obligatorios', life: 3000 })
        } else {
          cerrarModal()
          pacientesStore.$patch((state) => {
            if (pacienteSeleccionado.value.Zonificacion !== pacientesStore._zonificacion_seleccionada.Zonificacion) {
              state.paciente_seleccionado.U_PHR_CodDane = pacientesStore._zonificacion_seleccionada.CodSap
            }
            state.paciente_seleccionado.U_ACS_Ciudad_domicilio = pacientesStore._localizacion_seleccionada.nomMpio
            state.paciente_seleccionado.U_ACS_Dpto_domicilio = pacientesStore._localizacion_seleccionada.nomDpto
            state.paciente_seleccionado.U_ACS_NmbPct = pacienteSeleccionado.value.U_ACS_PrimerNmb + ' ' + pacienteSeleccionado.value.U_ACS_SegundoNmb + ' ' + pacienteSeleccionado.value.U_ACS_PrimerAplld + ' ' + pacienteSeleccionado.value.U_ACS_SegundoAplld
            state.paciente_seleccionado.name = pacienteSeleccionado.value.U_ACS_NmrIdentf + ' - ' + pacienteSeleccionado.value.U_ACS_PrimerNmb + ' ' + pacienteSeleccionado.value.U_ACS_SegundoNmb + ' ' + pacienteSeleccionado.value.U_ACS_PrimerAplld + ' ' + pacienteSeleccionado.value.U_ACS_SegundoAplld
          })
          pacientesStore.actualizarPaciente(pacienteSeleccionado.value, pacienteSeleccionado.value.Id).then(({ data }) => {
            Swal.fire(
              'Guardado!',
              data,
              'success'
            ).then(() => {
              emit('cambioData', pacienteSeleccionado.value)
              if (pacienteSeleccionado.value.U_ACS_Regimen === 'Subsidiado') {
                if (pacienteSeleccionado.value.U_ACS_Nivel === 'N1' || pacienteSeleccionado.value.U_ACS_Nivel === 'N2' || pacienteSeleccionado.value.U_ACS_Nivel === 'N3') {
                  pacientesStore.paciente_seleccionado.rango_nivel = pacienteSeleccionado.value.U_ACS_Nivel === 'N1' ? 'Subsidiado nivel 1' : (pacienteSeleccionado.value.U_ACS_Nivel === 'N2' ? 'Subsidiado nivel 2' : 'Subsidiado nivel 3')
                  if (ordenesStore._udf.mot_autoriza === '05' || ordenesStore._udf.mot_autoriza === '06') {
                    if (pacienteSeleccionado.value.U_ACS_Nivel === 'N2' || pacienteSeleccionado.value.U_ACS_Nivel === 'N3') {
                      if (ordenesStore._udf.mot_autoriza.CobroCuotaRecuperacion) {
                        ordenesStore.udf.porcentaje_cuota = parseInt(calculoCuotaStore._rangos_niveles.find(a => a.Code === pacienteSeleccionado.value.U_ACS_Nivel).U_PHR_PorCobro)
                        pacientesStore.paciente_seleccionado.ValorPorcentaje = parseInt(calculoCuotaStore._rangos_niveles.find(a => a.Code === pacienteSeleccionado.value.U_ACS_Nivel).U_PHR_PorCobro)
                      } else {
                        toast.add({ severity: 'info', summary: 'Atención', detail: `El contrato activo del cliente ${ordenesStore._header.cliente.CardName} exonera la cuota de recuperación para todos los pacientes cuando el motivo de autorización es ${ordenesStore._udf.mot_autoriza.Nombre} en nivel alto`, life: 8000 })
                      }
                    }
                  }
                } else {
                  pacientesStore.paciente_seleccionado.rango_nivel = 'Contributivo - RANGO INCORRECTO'
                }
              } else {
                ordenesStore.mostrar_calcular_cuota = true
                if (pacienteSeleccionado.value.U_ACS_Nivel === 'R1' || pacienteSeleccionado.value.U_ACS_Nivel === 'R2' || pacienteSeleccionado.value.U_ACS_Nivel === 'R3') {
                  pacientesStore.paciente_seleccionado.rango_nivel = pacienteSeleccionado.value.U_ACS_Nivel === 'R1' ? 'Contributivo rango 1' : (pacienteSeleccionado.value.U_ACS_Nivel === 'R2' ? 'Contributivo rango 2' : 'Contributivo rango 3')
                } else {
                  pacientesStore.paciente_seleccionado.rango_nivel = 'Subsidiado - NIVEL INCORRECTO'
                }
              }
              ordenesStore.mostrar_calcular_cuota = true
              pacientesStore.paciente_seleccionado.ValorCuota = rangosNiveles.value.find(e => e.Code === pacienteSeleccionado.value.U_ACS_Nivel).U_PHR_ValorCuota
              if (ordenesStore._udf.mot_autoriza.CobroCuotaModeradora) {
                ordenesStore.udf.cuota_moderadora = parseInt(rangosNiveles.value.find(e => e.Code === pacienteSeleccionado.value.U_ACS_Nivel).U_PHR_ValorCuota)
              } else {
                toast.add({ severity: 'info', summary: 'Atención', detail: `El contrato activo del cliente ${ordenesStore._header.cliente.CardName} exonera la cuota moderadora para todos los pacientes cuando el motivo de autorización es ${ordenesStore._udf.mot_autoriza.Nombre}`, life: 8000 })
              }
            })
          })
        }
      }
      const obtenerLocalizaciones = (ubicacion) => {
        localizacionesStore.obtenerLocalizaciones(ubicacion)
      }
      onMounted(() => {
      })
      return {
        cerrarModal,
        pacienteSeleccionado,
        displayModal,
        actualizandoPaciente,
        actualizarPaciente,
        generos,
        rangosNiveles,
        regimenes,
        obtenerLocalizaciones,
        listadoLocalizaciones,
        localizacionSeleccionada,
        pacientesStore
      }
    }
  }
</script>
<style>
  ::v-deep(.p-dropdown ) {
    height: 1.8rem;
    padding: 0rem;
  }
  ::v-deep(.p-dropdown .p-inputtext) {
    font-size: 0.75rem;
    padding: 0.3rem;
  }
  ::v-deep(.p-inputtext) {
    font-size: 0.75rem;
    padding: 0.25rem;
  }
  ::v-deep(.chevron-dropdown-none .p-dropdown-trigger) {
    display: none !important;
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
